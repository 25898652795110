import request from '@/utils/request';
import { jinqun, baseApi, userApi, orderApi, payApi, TokenApi, Logistics, ScoreTopApi, HjCPay } from '@/config';

// 获取首页商品推荐列表1111
export function getGoodsList(params) {
  return request({
    url: baseApi + 'gin/common/goods/home.list',
    // url: ScoreTopApi + 'goodsInfo/getAppGoodsList',
    method: 'get',
    params: params
  });
}
// 新—************获取首页商品推荐列表1111
// export function getAPPList(data) {
//     return request({
//         // url: baseApi + 'apigoods/home/list',
//         url: ScoreTopApi + 'goodsInfo/getAppGoodsList',
//         method: 'post',
//         data,
//         headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
//     })
// }
// 获取首页商品推荐列表1111
export function apishoplist(params) {
  return request({
    url: baseApi + 'apicompare/goods/scorerop-list',
    // url: ScoreTopApi + 'goodsInfo/getNewScoreTopGoodsList', // SPU
    method: 'get',
    params: params
  });
}
// 获取首页商品搜索列表
export function getSearchGoodsList(params) {
  return request({
    url: baseApi + 'apigoods/goods/list',
    method: 'get',
    params: params
  });
}
// 获取首页商品搜索列表推荐商品

export function getSearchGoodsListRecommend(params) {
  return request({
    url: baseApi + 'apigoods/goods/logistics/recommend',
    method: 'get',
    params: params
  });
}

// 获取轮播图组
export function getBannerList(params) {
  return request({
    url: baseApi + 'gin/common/goods/banner.list',
    method: 'get',
    params: params
  });
}

//获取商品分类列表
export function getGoodsClassifyList(params) {
  return request({
    url: baseApi + 'apigoods/goods-classifys/classifys-list',
    method: 'get',
    params: params
  });
}

//获取品牌分类列表
export function getBrandClassifyList(params) {
  return request({
    url: 'apishop/goods-v2/type-list',
    method: 'get',
    params: params
  });
}

//获取首页导航条显示状态配置
export function getNavStatus() {
  return request({
    url: baseApi + 'apishop/config/nav-status',
    method: 'get'
  });
}

//返现专区相关-开始

//获取商品列表（返现）
export function getCashGoodsList(params) {
  return request({
    url: baseApi + 'apishop/link/share',
    method: 'get',
    params: params
  });
}

//获取商品详情
export function getGoodsDetails(params) {
  return request({
    url: baseApi + 'apigoods/goods/detail',
    method: 'get',
    params: params
  });
}

//获取商品规格
export function getGoodsConfig(params) {
  return request({
    url: baseApi + 'apigoods/goodsspecifications/attributeList',
    method: 'get',
    params: params
  });
}
//获取商品规格------测试
export function csgetGoodsConfig(params) {
  return request({
    url: baseApi + 'apigoods/demo',
    method: 'get',
    params: params
  });
}
//获取商品评价
export function getGoodsComment(params) {
  return request({
    url: baseApi + 'gin/comment/goods/get',
    method: 'get',
    params: params
  });
}

// //创建订单
// export function submitOrder(data) {
//     return request({
//         url: 'apishop/order/create-v2',
//         method: 'post',
//         data
//     })
// }
//创建订单
export function submitOrder(data) {
  return request({
    url: orderApi + 'api/Orders/AddOrdersInfo',
    method: 'post',
    data: data
  });
}
//进群操作
export function AddOrdersInfo(data) {
  console.log(data);
  return request({
    url: jinqun + 'home/setEnterRetreat',
    method: 'post',
    data: data
  });
}

//支付尾款
export function ComputerPresell(data) {
  return request({
    url: orderApi + 'api/Orders/ComputerPresell',
    method: 'post',
    data: data
  });
}
// 预售限购数量
export function purchaseNum(params) {
  return request({
    url: baseApi + 'apigoods/activity/limit/num',
    method: 'get',
    params: params
  });
}

//支付宝支付
export function aliPay(data) {
  return request({
    url: 'apishop/payment/h5pay',
    method: 'post',
    data: data
  });
}

//微信支付
export function wechatPay(data) {
  return request({
    url: 'apishop/payment/wechatpaymweb',
    method: 'post',
    data: data
  });
}

//返现专区相关-结束

//获取商品列表（拼团）
export function getPTGoodsList(params) {
  return request({
    url: baseApi + 'apishop/team/list',
    method: 'get',
    params: params
  });
}

//获取商品列表（接龙）
export function getJLGoodsList(params) {
  return request({
    url: baseApi + 'apigoods/activity/list',
    method: 'get',
    params: params
  });
}

//获取微信openid
export function getWeChatOpenId(data) {
  return request({
    url: TokenApi + 'Auth/GetWXOpenId',
    method: 'post',
    data: data
  });
}

// 根据token获取用户数据
export function GetUserInfoByToken(data) {
  return request({
    // F_UserInfo/GetUserInfoByToken
    url: TokenApi + 'F_UserInfo/GetUserInfoByToken',
    method: 'post',
    data: data
  });
}
// 重置密码
export function SetPwd(data) {
  return request({
    // F_UserInfo/GetUserInfoByToken
    url: TokenApi + 'Auth/SetPwd',
    method: 'post',
    data: data
  });
}

//绑定微信
// token:string 登录token标识
// openid: string 之前接口返回的openid
// unionid: string 微信登录的唯一标识（选填）
// type: strign登录设备类型，允许值如：android - 安卓端APP，ios - 苹果端APP，null - 未知设备类型（选填）
// nickname: string 用户昵称，如果传了会在原昵称为空的时候进行完善（选填）
// headimgurl: string 用户头像，如果传了会在原头像为空的时候进行完善（选填）
export function bindingWeChat(data) {
  return request({
    url: 'apishop/login/bind-wx-openid',
    method: 'post',
    data: data
  });
}

//微信JSSDK支付（微信内网页支付/小程序支付）
// token	string		登录标识
// orders_sn	string		订单号
// openid	string	用户绑定的微信openid	当前打开的微信openid
// equipment string	选填	fission	支付设备标识merchantmade - APP所在的公众号 fission - H5所在的微信公众号
export function wechatJSSDKPay(data) {
  return request({
    url: orderApi + 'api/Pay/MergePaymentByApp',
    method: 'post',
    data: data
  });
}

// 通联批量支付
export function batchcollection(data) {
  return request({
    url: orderApi + 'api/Pay/batchcollection',
    method: 'post',
    data: data
  });
}
// 集采代支付
export function JCHelpPayNew(data) {
  return request({
    url: HjCPay + 'order/behalfPay',
    method: 'post',
    data: data
  });
}
// 集采代付商品详情
export function GetAuctionGoodsDetail(params) {
  return request({
    url: HjCPay + 'order/getAuctionGoodsDetail',
    method: 'get',
    params: params
  });
}
// 汇速递商品详情
export function Getchildwaybill(params) {
  return request({
    url: baseApi + 'express/api/home/get-child-pid',
    method: 'get',
    params: params
  });
}
// 汇速递代支付接口
export function HSDhelpcall_pay(data) {
  return request({
    url: baseApi + 'express/api/home/call_pay',
    method: 'post',
    data: data
  });
}

// 原生支付宝支付（礼品卡）
export function AlipayPaymentByApp(data) {
  return request({
    url: orderApi + 'api/Pay/PaymentByWap',
    method: 'post',
    data: data
  });
}

// 原生微信支付(礼品卡)
export function MergePaymentByApp(data) {
  return request({
    url: orderApi + 'api/Pay/PaymentByH5',
    method: 'post',
    data: data
  });
}
//获取拼团团队列表
// activity_team_id：int 拼团活动的id
// product_id：int（测试数据）产品的id
// limit ：int 条数
// offset ：int 开始
export function getCollageList(params) {
  return request({
    url: 'apishop/team/team-user-list',
    method: 'get',
    params: params
  });
}

//活动详情团队信息
// link_id：int 接龙活动ID
// link_priduct_id：int 接龙活动ID
export function getSolitaireList(params) {
  return request({
    url: baseApi + 'apigoods/activity/detail/team',
    method: 'get',
    params: params
  });
}

//获取钱包可用余额
//"token":string
export function getBalance(params) {
  return request({
    url: baseApi + 'apishop/userinfo/getBalance',
    method: 'get',
    params: params
  });
}

//比一比获取价格走势
//goods_id_all: str//商品id，
//area ：可选传值 7 15 30 不传显示所有
export function getPriceTrend(params) {
  return request({
    url: baseApi + 'apicompare/compare/goods-price-list',
    method: 'get',
    params: params
  });
}
//比一比获取评价走势
//goods_id_all: str//商品id，
//area ：可选传值 7 15 30 不传显示所有
export function getevaluationTrend(params) {
  return request({
    url: baseApi + 'apicompare/compare/goods-evaluation-list',
    method: 'get',
    params: params
  });
}
//获取微信wx.config配置
//equipment   设备标识
//url 当前页面地址
export function getWXConfig(data) {
  return request({
    url: baseApi + 'apishop/payment/wxConfig',
    method: 'get',
    data: data
  });
}
//获取微信wx.config配置
//equipment   设备标识
//url 当前页面地址
export function GetWXSignature(data) {
  return request({
    // url: TokenApi +'Auth​/GetWXSignature',
    url: TokenApi + 'Auth/GetWXSignature',
    method: 'post',
    data: data
  });
}

//登录接口 - 直播端
//buy_id 当前购买用户ID
//call_type 三方标识（用于回调标识，live-直播）
//buy_phone 当前购买用户手机，经过base64编码并排除最后的等于号。如：MTc2MQ==传输的值是MTc2MQ
export function liveLogin(data) {
  return request({
    url: 'apishop/login/phone-live',
    method: 'post',
    data: data
  });
}

//第三方支付 - 通联
//token:用户token
//orders_sn://订单编号
//code: 支付编号 AliPay-支付宝 WeChat-微信
export function thirdPartyPay(data) {
  return request({
    url: 'apishop/payment/allin-v2',
    method: 'post',
    data: data
  });
}

// 分享链接生成
export function generateShare(params) {
  return request({
    url: baseApi + 'apishop/share',
    method: 'get',
    params: params
  });
}
export function shareClick(data) {
  return request({
    url: baseApi + 'apishop/share/click',
    method: 'post',
    data: data
  });
}

// 获取首页店铺推荐列表
export function getshopList(data) {
  return request({
    url: baseApi + 'apishop/share/click',
    method: 'post',
    data: data
  });
}

// 比一比个性化设置
export function standardLabel(params) {
  return request({
    url: baseApi + 'apicompare/compare/standard-label',
    method: 'get',
    params: params
  });
}

// 比一比个性化添加
export function savestandardLabel(data) {
  return request({
    url: baseApi + 'apicompare/compare/save-standard-label',
    method: 'post',
    data: data
  });
}

// 商品属性
export function propert(params) {
  return request({
    url: baseApi + 'apigoods/goods/propert',
    method: 'get',
    params: params
  });
}

// 获取所有一级省市乡镇数据
export function GetOneLevelList(params) {
  return request({
    url: TokenApi + 'Region/GetOneLevelList',
    method: 'get',
    params: params
  });
}

// 获取下一级省市乡镇数据
export function GetNextLevelList(params) {
  return request({
    url: TokenApi + 'Region/GetNextLevelList',
    method: 'get',
    params: params
  });
}

// 得到不可发货区域数据
export function GetCannotSend(data) {
  return request({
    url: Logistics + 'api/Adm_CannotSend/IsCanSend',
    method: 'post',
    data: data
  });
}
// 商品配送地址
export function deliveryList(params) {
  return request({
    url: baseApi + 'apigoods/goods/support/delivery/list',
    method: 'get',
    params: params
  });
}
// 多商品是否支持发货
export function MoredeliveryList(params) {
  return request({
    url: baseApi + 'apigoods/goods/verify/delivery',
    method: 'get',
    params: params
  });
}
// ***************券中心***********************
// 券中心列表接口
export function corelist(params) {
  return request({
    url: baseApi + 'apicoupon/core/list',
    method: 'get',
    params: params
  });
}
// 根据券ID获取相关联的商品信息
export function goodslist(params) {
  return request({
    url: baseApi + 'apicoupon/core/goods-list',
    method: 'get',
    params: params
  });
}
// 绑定用户上下级关系
export function bindUser(params) {
  return request({
    url: baseApi + 'apicoupon/core/bind-user',
    method: 'get',
    params: params
  });
}
// 获取省市区数据
export function getProvincesAndCities(params) {
  return request({
    url: baseApi + 'apiindex/district-checke',
    method: 'get',
    params: params
  });
}
// 反查接口
export function districtContraryChecke(params) {
  return request({
    url: baseApi + 'apiindex/district-contrary-checke',
    method: 'get',
    params: params
  });
}
//  获取城市列表
export function GetDistrictByParentCode(params) {
  return request({
    url: Logistics + 'api/Dic/GetDistrictByParentCode',
    method: 'get',
    params: params
  });
}
//  根据券id获取快递公司名称
export function GetCouponDetail(params) {
  return request({
    url: Logistics + 'api/Adm_Pay/GetCouponDetail',
    method: 'get',
    params: params
  });
}

//  获取物流信息（多包裹）通过单号
export function MultiplePackages(params) {
  return request({
    url: Logistics + 'api/Synthetical/GetLogisticsInfoByOrderNo_MultiplePackages',
    method: 'get',
    params: params
  });
}

//  02.1.3 获取商品的联系客服的通信信息
export function chatInfo(params) {
  return request({
    url: baseApi + 'apigoods/goods/chat-info',
    method: 'get',
    params: params
  });
}

//  03.1 根据sku-id获取商品最优优惠券
export function couponfirst(params) {
  return request({
    url: baseApi + 'apicoupon/goods/first',
    method: 'get',
    params: params
  });
}

/******************新首页接口********************/
// 获取金刚位 token可以不传
export function navilocation(params) {
  return request({
    url: baseApi + 'apiindex/get-navigation-location',
    method: 'get',
    params: params
  });
}
// 首页推送内容
export function PushContent(params) {
  return request({
    url: baseApi + 'apiindex/getList',
    method: 'get',
    params: params
  });
}
// 金刚位修改
export function UpdateNav(data) {
  return request({
    url: baseApi + 'apiindex/edit-navigation-location',
    method: 'post',
    data: data
  });
}
// 用户是否对当前文章点过赞
export function ISgiveLike(params) {
  return request({
    url: baseApi + 'apishop/article/whether-give-like',
    method: 'get',
    params: params
  });
}

// 分类品牌选择页面
export function getCategoryBrandList(params) {
  return request({
    url: baseApi + 'apigoods/goods/category-brand-list',
    method: 'get',
    params: params
  });
}

// APP锚点触发事件
export function viewUrl(params) {
  return request({
    url: baseApi + 'apishop/share/view',
    method: 'get',
    params: params
  });
}

// 银行卡支付时确认
export function PayConfirmPayment(data) {
  return request({
    url: orderApi + 'api/Pay/PayConfirmPayment',
    method: 'post',
    data: data
  });
}

// 重新银行卡支付发送验证码
export function ResendVerificationCode(params) {
  return request({
    url: payApi + 'api/TLPayment/ResendVerificationCode',
    method: 'get',
    params: params
  });
}

// 地址填充接口
export function getcardsend(data) {
  return request({
    url: baseApi + 'apishop/couponcard/card-send',
    method: 'post',
    data: data
  });
}

// *************新比一比接口
// 添加商品收藏
export function favouriteAdd(params) {
  return request({
    url: baseApi + 'apicompare/goods/favourite-add',
    method: 'get',
    params: params
  });
}
// 移除商品收藏
export function favouriteDelete(params) {
  return request({
    url: baseApi + 'apicompare/goods/favourite-delete',
    method: 'get',
    params: params
  });
}

// 好物汇配置信息接口
export function getcnfbytype(params) {
  return request({
    url: baseApi + 'apiindex/getcnfbytype',
    method: 'get',
    params: params
  });
}

// 商详添加订阅
export function subscribeGoods(data) {
  return request({
    url: baseApi + 'apishop/add/subscribe',
    method: 'post',
    data: data
  });
}
// 取消商品订阅
export function deletesubscribeGoods(params) {
  return request({
    url: baseApi + 'apishop/delete/subscribe',
    method: 'get',
    params: params
  });
}
// 商品订阅列表
export function subscribegoodslist(params) {
  return request({
    url: baseApi + 'apishop/subscribe/list',
    method: 'get',
    params: params
  });
}

/**********健康讲堂***************/
// 课程列表
export function healthyforumlst(params) {
  return request({
    url: baseApi + 'apishop/healthy-forum/list',
    method: 'get',
    params: params
  });
}

// 课程评论列表
export function healthyAssess(params) {
  return request({
    url: baseApi + 'apishop/get/assess',
    method: 'get',
    params: params
  });
}

// 职业认证信息-科室列表
export function configLists(params) {
  return request({
    url: baseApi + 'apiprofessional/auth/configlists',
    method: 'get',
    params: params
  });
}
// 对外提供用户抽奖次数
export function Getreward_num(token) {
  return request({
    url: tapiApi + '/customer/v2/lottery/get/reward_num',
    method: 'get',
    headers: {
      Authorization: "Bearer ".concat(token)
    }
  });
}